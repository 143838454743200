@import '../../assets/styles/global';

.blog {
  max-width: $web-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-top: $header-height;

  &__inner {
    max-width: 360px;
    padding: 40px 0 40px 85px;

    @include mobile {
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }

    &.lookbook, &.launchparty {
      max-width: 800px;
    }

    &.post {
      max-width: 630px;
      padding: 40px 0 0;
      margin-left: auto;
      margin-right: auto;
    }

    @include mobile {
      max-width: 290px;
      padding: 40px 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__header {
    font-family: $bold;
    font-size: 21px;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 21px;

    @include mobile {
      margin-left: 20px;
    }
  }

  &__subheader {
    font-size: 12px;
    letter-spacing: 0.5px;
    max-width: 80%;

    &.line-height {
      line-height: 1.75em;
    }

    &.show-links {
      a {
        text-decoration: underline;
      }
    }

    a:hover {
      text-decoration: underline;
    }

    div {
      margin-bottom: 15px;
    }

    @include mobile {
      margin-left: 20px;
      // max-width: 100%;
    }
  }

  &__link {
    font-size: 12px;
    letter-spacing: 0.5px;
    text-decoration: underline;
    margin-bottom: 10px;

    &.post {
      letter-spacing: 3px;
      text-transform: uppercase;

      @include mobile {
        margin-left: 20px;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__images {
    padding-top: 10px;
    img {
      width: 255px;
      margin-right: 8px;
    }

    @include mobile {
      div {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          margin-bottom: 8px;
        }
      }
    }
  }
}
