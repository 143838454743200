// FONTS
$default: 'Poppins';
$medium: 'Poppins-Medium';
$bold: 'Poppins-Bold';
$boldItalic: 'Poppins-BoldItalic';
$semi-bold: 'Poppins-SemiBold';
$edgy: 'Relapse';

// COLORS
$white: #FFFFFF;
$black: #000000;
$neon-green: #D0FF00;

$header-height: 54px;
$web-max-width: 900px;
$mobile-width: 800px;

@mixin mobile {
  @media screen and (max-width : $mobile-width) {
    @content;
  }
}
