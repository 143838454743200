body {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

@font-face {
    font-family: 'Relapse';
    src: url('./assets/fonts/RElapse-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-BoldItalic';
    src: url('./assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
}
